// Packages
import React from "react";
import { IonButton } from "@ionic/react";
import { computed, action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import {
  CheckOutlined,
  LoadingOutlined,
  RedoOutlined,
} from "@ant-design/icons";
// Type Definitions
import { ICheckIn } from "../../../shared/interfaces";
import { track } from "../../../plugins/analytics";
import { Link } from "react-router-dom";
interface IProps {
  checkInStore?: any;
  checkIn: ICheckIn;
  title: string;
  description?: string;
  allowRevertStep?: boolean;
  allowIncrementStep?: boolean;
  submitAnswers?: () => void;
  skipToCheckIn?: boolean;
}

@inject("checkInStore")
@observer
class StepNavigation extends React.Component<IProps> {
  @observable
  public revertLoading = false;

  @observable
  public incrementLoading = false;

  @computed get revertStepText() {
    let text = "";
    switch (this.props.checkIn.status) {
      case "step-2":
        text = "Step One";
        break;
      case "step-3":
        text = "Step Two";
        break;
      case "complete":
        text = "Step Three";
        break;
      default:
        text = "Previous Step";
    }
    return text;
  }

  @computed get completeStepText() {
    let text = "";
    switch (this.props.checkIn.status) {
      case "step-1":
        text = "Step One";
        break;
      case "step-2":
        text = "Step Two";
        break;
      case "step-3":
        text = "Check-in";
        break;
      default:
        text = "This Step";
    }
    return text;
  }

  @action.bound
  revertStep() {
    this.revertLoading = true;
    this.props.checkInStore.revertStep();
  }

  @action.bound
  async incrementStep() {
    this.incrementLoading = true;
    this.props.checkInStore.incrementStep();
    if (this.props.checkIn.status === "step-3") {
      track("Completed Check In");
      if (this.props.submitAnswers) {
        let a = await this.props.submitAnswers();
        this.incrementLoading = false;
      }
    }
  }

  render() {
    return (
      <div style={{ marginTop: "2rem" }}>
        <div className="center-text">
          <h3 className="step-title">{this.props.title}</h3>
          <p className="step-description">{this.props.description}</p>
          <div className="flexBoxRow" style={{ justifyContent: "center" }}>
            {this.props.allowRevertStep &&
              this.props.checkIn.status !== "complete" &&
              !this.props.skipToCheckIn && (
                <IonButton
                  color="light"
                  onClick={this.revertStep}
                  disabled={this.revertLoading}
                  className="check-in-next-btn"
                >
                  Back
                </IonButton>
              )}
            {(!this.props.allowRevertStep || this.props.skipToCheckIn) && (
              <Link
                to="/reviews-reflect"
                className="check-in-next-btn"
                onClick={() => {
                  if (this.props.skipToCheckIn)
                    this.props.checkInStore.revertStep();
                }}
              >
                Back
              </Link>
            )}

            {this.props.checkIn.status === "complete" && (
              <IonButton
                color="primary"
                onClick={this.revertStep}
                disabled={this.revertLoading}
                className="check-in-next-btn"
              >
                Edit Check In
              </IonButton>
            )}

            {this.props.allowIncrementStep && (
              <button
                onClick={this.incrementStep}
                disabled={this.incrementLoading}
                className="nextButton"
              >
                {/* {this.incrementLoading ? <LoadingOutlined /> : <CheckOutlined />} */}
                {this.incrementLoading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    {this.props.checkIn.status === "step-3" ? (
                      "Complete Check-in"
                    ) : (
                      <>Next -&gt;</>
                    )}
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default StepNavigation;
