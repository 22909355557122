//Do not format this file with Prettier as it will break the logic
import { IActivity } from "../../shared/interfaces";
import moment from "moment";
const currentDate = new Date();

export const activityComparator = (a: IActivity, b: IActivity) => {
  if (a.status === b.status) {
    if (a.type === "routine" && a.frequency && b.frequency) {
      // Sort by frequency
      if (a.frequency < b.frequency) {
        return 1;
      }
      if (a.frequency > b.frequency) {
        return -1;
      }
      return 0;
    }
    // Logic for "completionType" soon/sometime and updatedAt-based sorting
    else if (
        a.type === "task" &&
        a.updatedAt &&
        b.updatedAt &&
        a.completionType &&
        // eslint-disable-next-line no-mixed-operators
        b.completionType &&
        (a.completionType === "sometime" &&
        // eslint-disable-next-line no-mixed-operators
        b.completionType === "sometime") || (a.completionType === "soon" && b.completionType === "soon")
    ) {
      const aPriority = a.goal && a.goal.isPriority ? a.goal.isPriority : 0;
      const bPriority = b.goal && b.goal.isPriority ? b.goal.isPriority : 0;
      const aUpdatedAt = new Date(a.updatedAt);
      const bUpdatedAt = new Date(b.updatedAt);

      if (aPriority !== bPriority) {
        return bPriority - aPriority; // Priority tasks first
      }

      // Most recently updated comes first for both priority and non-priority
      return bUpdatedAt.getTime() - aUpdatedAt.getTime();
    } else if (a.type === "task" && a.completionDate && b.completionDate) {
      const aPriority = a.goal && a.goal.isPriority ? a.goal.isPriority : 0;
      const bPriority = b.goal && b.goal.isPriority ? b.goal.isPriority : 0;
      const aDate = a.completionDate
        ? a.completionDate.length > 10
          ? new Date(moment(a.completionDate).format("YYYY-MM-DD"))
          : new Date(a.completionDate)
        : null;
      const bDate = b.completionDate
        ? b.completionDate.length > 10
          ? new Date(moment(b.completionDate).format("YYYY-MM-DD"))
          : new Date(b.completionDate)
        : null;
      const isAOverdue = aDate && aDate < currentDate;
      const isBOverdue = bDate && bDate < currentDate;

      if (isAOverdue && isBOverdue) {
        if (aDate && bDate) {
          if (aDate.getTime() !== bDate.getTime()) {
            return aDate.getTime() - bDate.getTime();
          }
        }
        return bPriority - aPriority; // Higher priority comes first
      }

      if (isAOverdue) return -1; // Overdue tasks come first
      if (isBOverdue) return 1; // Overdue tasks come first

      // Handle normal tasks
      if (aPriority !== bPriority) {
        return bPriority - aPriority; // Higher priority comes first
      }

      if (aDate && bDate) {
        return aDate.getTime() - bDate.getTime(); // Earlier date comes first
      }

      return 0; // Fallback if no criteria matches
    }
  }

  // Sort by status - push completed activities to the bottom
  return a.status === "incomplete" ? -1 : 1;
};
