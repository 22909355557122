// Packages
import React from "react";
import { IonInput, IonPage, IonSpinner, IonTextarea } from "@ionic/react";
import { withRouter, Link } from "react-router-dom";
import { observable, autorun, action, computed } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import moment from "moment";
// Components
import { Empty, Breadcrumb, Select, Button, message, Popconfirm } from "antd";
import GoalActivityTimeline from "../../shared/GoalActivityTimeline/GoalActivityTimeline";
// Styles
import "./Goal.scss";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { IEditGoalForm, IGoal } from "../../shared/interfaces";
import FlagSVG from "../../components/svgComponents/FlagSVG";
import { NotFilledStarSVG } from "../../components/svgComponents/NotFilledStarSVG";
import { FilledStarSVG } from "../../components/svgComponents/FilledStarSVG";
import { CrossSVG } from "../../components/svgComponents/CrossSVG";
import { track } from "../../plugins/analytics";
import TeamsSVG from "../../components/svgComponents/TeamSVG";

interface IProps extends RouteComponentProps {
  goalStore?: any;
  activityStore?: any;
  match: any;
}

@inject("goalStore", "activityStore")
@observer
class Goal extends React.Component<IProps> {
  componentDidMount() {
    this.props.goalStore.updateGoalModal = true;
  }

  @observable
  public goalLoading = false;
  @observable
  public isActive = 0;
  @observable
  public loading = false;
  @observable
  public error = null;
  @observable public editedGoal: IEditGoalForm = {
    name: "",
    description: "",
    completedOn: "",
    isActive: true,
    isPriority: false,
    isDeleted: false,
  };
  @observable
  public activityLoading = false;

  @computed get goal() {
    return this.props.goalStore.activeGoal;
  }

  @computed get goalId() {
    return this.props.match.params.id;
  }
  @disposeOnUnmount
  public userTokenReaction = autorun(() => {
    if (this.props.goalStore.updateGoalModal) {
      if (this.goal.isActive === 1) {
        this.goal.isActive = true;
      } else if (this.goal.isActive === 0) {
        this.goal.isActive = false;
      }
      if (this.goal.isPriority === 1) {
        this.goal.isPriority = true;
      } else if (this.goal.isPriority === 0) {
        this.goal.isPriority = false;
      }
      if (this.goal.isDeleted === 1) {
        this.goal.isDeleted = true;
      } else if (this.goal.isDeleted === 0) {
        this.goal.isDeleted = false;
      }
      this.editedGoal = this.goal;
    }
  });

  @action.bound
  public updateGoal = async (action?: string) => {
    //e.preventDefault();
    this.loading = true;
    track("Goal Edited");
    this.isActive = this.editedGoal.isActive ? 1 : 0;
    const resError = await this.props.goalStore.updateGoal(
      this.editedGoal,
      this.isActive,
      this.editedGoal.isPriority ? 1 : 0,
      action
    );
    this.error = resError;
    this.loading = false;
    if (!this.error) {
      this.props.goalStore.updateGoalModal = false;
      if ((action && !action.includes("sendToCompleted")) || !resError) {
        message.destroy();
        message.success("Goal updated!");
      }
    }
  };

  @disposeOnUnmount
  public goalAutorun = autorun(() => {
    if (!this.goal || this.goal.id !== this.goalId) {
      this.getGoal(this.goalId);
    }

    this.getActivities();
    this.props.goalStore.getGoals();
  });

  @action.bound
  public getActivities() {
    this.activityLoading = true;
    const startDate = moment().subtract(100, "years").format("YYYY-MM-DD");
    const endDate = moment().add(100, "years").format("YYYY-MM-DD");

    this.props.activityStore
      .getActivities("task", startDate, endDate, this.goal.id)
      .then(() => {
        this.activityLoading = false;
      });
    this.props.activityStore
      .getActivities("routine", startDate, endDate, this.goal.id)
      .then(() => {
        this.activityLoading = false;
      });
  }

  @action.bound
  public getGoal(id: string) {
    this.goalLoading = true;
    this.props.goalStore.getGoal(id).then((goal: IGoal) => {
      this.goalLoading = false;
    });
  }

  public componentWillUnmount() {
    this.props.activityStore.tasks = [];
    this.props.goalStore.updateGoalModal = false;
    this.props.goalStore.createGoalModal = false;
  }
  @action.bound
  public handlePriorityClick = async () => {
    if (this.goal.isActive === false) {
      return message.warning("Inactive goals cannot be set as priority.");
    }
    if (
      this.props.goalStore.priorityGoals.length === 5 &&
      !this.goal.isPriority
    ) {
      return message.warning("You can only have 5 priority goals.");
    }
    this.goal.isPriority = !this.goal.isPriority;
    let action = this.goal.isPriority
      ? "sendToPriorityFromActive"
      : "sendToActiveFromPriority";

    await this.updateGoal(action);
    this.getActivities();
  };

  public renderGoal = () => {
    if (!this.goal) {
      return <Empty />;
    } else {
      return (
        <div className="mlr-auto w-m-850">
          {/* <Breadcrumb>
          <Breadcrumb.Item key="goals">
            <Link to="/goals">
              <LineChartOutlined />
              &nbsp;Goals
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="goal">
            {this.goal.name}
          </Breadcrumb.Item>
        </Breadcrumb> */}

          <br />
          <div className="flex-header">
            <div className="header-container">
              <div className={"IconWrapper"}>
                <FlagSVG className={"active"} />
              </div>
              <div
                className="title-write"
                contentEditable={true}
                onInput={(e) => {
                  if (
                    e.currentTarget.textContent === "" ||
                    e.currentTarget.textContent === null
                  ) {
                    alert("Goal name cannot be empty");
                  }
                }}
                onBlur={(e) => {
                  // Update the goal when IonInput loses focus
                  this.editedGoal.name = e.currentTarget.textContent!;
                  if (this.editedGoal.name !== "") {
                    this.updateGoal();
                  }
                }}
                placeholder="Add a name"
              >
                {this.editedGoal.name}
              </div>

              {/* <h2 className="title">{this.goal.name}</h2> */}
              {/* {this.goal.mentorId && (
                <div className="shared-btn">
                  <TeamsSVG color="#323232" />
                </div>
              )} */}
            </div>
            <div
              className="closeSVG"
              onClick={() => {
                this.props.goalStore.updateGoalModal = false;
                this.props.goalStore.createGoalModal = false;
                this.props.history.push("/goals");
              }}
            >
              <CrossSVG />
            </div>
          </div>
          <div className="desc">
            <div
              className="title-write"
              contentEditable={true}
              onBlur={(e) => {
                // Update the goal when IonInput loses focus
                this.editedGoal.description = e.currentTarget.textContent!;
                if (this.editedGoal.name !== "") {
                  this.updateGoal();
                }
              }}
              placeholder="Add a description"
            >
              {this.editedGoal.description}
            </div>
            {/* <IonInput
              className="goalDescInput"
              value={this.editedGoal.description}
              onIonChange={(e) => {
                this.editedGoal.description = e.detail.value!;
              }}
              onIonBlur={() => {
                // Update the goal when IonInput loses focus
                this.updateGoal();
              }}
              placeholder="Add a description"
            /> */}
          </div>
          <div className="flexBoxRow">
            <Select
              defaultValue="active"
              value={
                this.editedGoal.status === "complete"
                  ? "completed"
                  : this.editedGoal.isActive === true
                  ? "active"
                  : "inactive"
              }
              className={
                this.editedGoal.status === "complete"
                  ? "status-completed"
                  : this.editedGoal.isActive === true
                  ? "status-active"
                  : "status-inactive"
              }
              onChange={(value) => {
                let action;
                switch (value) {
                  case "active":
                    action = "sendToActive";
                    action +=
                      this.editedGoal.isActive === false
                        ? "FromInactive"
                        : this.editedGoal.status === "complete"
                        ? "FromComplete"
                        : "";
                    this.editedGoal.isActive = true;
                    this.editedGoal.status = "incomplete";
                    break;
                  case "inactive":
                    action = "sendToInactive";
                    action += this.editedGoal.isPriority
                      ? "FromPriority"
                      : this.editedGoal.status === "complete"
                      ? "FromComplete"
                      : this.editedGoal.isActive === true && "FromActive";
                    this.editedGoal.isActive = false;
                    this.editedGoal.isPriority &&
                      message.warning(
                        "Inactive goals cannot be set as priority."
                      );
                    this.editedGoal.isPriority = false;
                    this.editedGoal.status = "incomplete";

                    break;
                  case "completed":
                    this.editedGoal.isPriority = false;
                    this.editedGoal.status = "complete";
                    message.success("Congratulations! Goal completed!");
                    action = "sendToCompleted";
                    action +=
                      this.editedGoal.isActive === false
                        ? "FromInactive"
                        : this.editedGoal.isPriority
                        ? "FromPriority"
                        : "FromActive";
                    break;
                  default:
                    this.editedGoal.isActive = true;
                    break;
                }
                // const isActive =
                //   value === "active"
                //     ? true
                //     : value === "inactive"
                //     ? false
                //     : false;
                // const isCompleted = value === "completed" ? true : false;
                // this.editedGoal.isActive = isActive;
                // this.editedGoal.completedOn = isCompleted ? moment().format("YYYY-MM-DD") : "";
                this.updateGoal(action);
              }}
            >
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
              <Select.Option value="completed">Completed</Select.Option>
            </Select>
            {this.goal.status === "incomplete" && (
              <button
                onClick={() => {
                  this.handlePriorityClick();
                }}
                className={`priority-btn ${
                  !this.editedGoal.isActive
                    ? "priority-btn-inactive"
                    : this.goal.isPriority && this.editedGoal.isActive
                    ? "active-priority"
                    : "priority-btn-active"
                }`}
              >
                {this.goal.isPriority ? (
                  <div className="priority-text">
                    <FilledStarSVG color={"#ffc773"} />
                    Priority
                  </div>
                ) : (
                  <div className="priority-text">
                    {!this.editedGoal.isActive ? (
                      <NotFilledStarSVG color="#939597" />
                    ) : (
                      <NotFilledStarSVG color="black" />
                    )}
                    Priority
                  </div>
                )}
              </button>
            )}
            {this.goal.mentorId && (
              <Popconfirm
                className="delete-popover"
                title={
                  <div>
                    Shared with <br />
                    {this.goal.mentor.name && (
                      <>{this.goal.mentor.name}:</>
                    )}{" "}
                    {this.goal.mentor.email}
                  </div>
                }
                onConfirm={() => {
                  const action = this.goal.isPriority
                    ? "refetchPriorityGoals"
                    : this.goal.status === "complete"
                    ? "refetchCompleteGoals"
                    : this.goal.isActive
                    ? "refetchActiveGoals"
                    : "refetchInactiveGoals";
                  this.props.goalStore.disconnectOrDeleteGoal(
                    this.goal.id,
                    false,
                    action
                  );
                }}
                okText="Stop sharing"
                cancelText="Cancel"
              >
                <button
                  className="shared"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <TeamsSVG color="#FF7E1D" />
                  Shared
                </button>
              </Popconfirm>
            )}
          </div>
          {this.activityLoading ? (
            <div className="min-h-40vh">
              <IonSpinner />
            </div>
          ) : (
            <div className="goal-timeline">
              <GoalActivityTimeline goal={this.goal} />
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    return (
      <IonPage className="scroll-page">
        <div className="main-content ion-padding">
          {this.goalLoading ? (
            <div className="min-h-40vh">
              <IonSpinner />
            </div>
          ) : (
            this.renderGoal()
          )}
        </div>
      </IonPage>
    );
  }
}

export default withRouter(Goal);
