// Libraries
import { API, Auth } from "aws-amplify";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";

import { identify, track } from "../analytics";

interface IProps extends RouteComponentProps {
  userStore: any;
}
export const authSignUp = async (
  props: IProps,
  name: string,
  email: string,
  password: string,
) => {
  const username = email.toLowerCase().trim();
  track("Signed Up");
  try {
    if(name === "" || email === "" || password === ""){
      return "Please fill out all fields";
    }
    await Auth.signUp({ 
     username: username,
    password: password,
    attributes: {
      name: name,
    }}
    );
  } catch (err) {
    return err.message;
  }
};

export const authConfirmSignUp = async (
  props: IProps,
  email: string,
  code: string
) => {
  const username = email.toLowerCase().trim();

  try {
    await Auth.confirmSignUp(username, code, {});
  } catch (err) {
    return err.message;
  }
};

export const authSignIn = async (
  props: IProps,
  email: string,
  password: string,
  newUser?: boolean
) => {
  const username = email.toLowerCase().trim();

  try {
    const user = await Auth.signIn(username, password);
    identify();
    track("Signed In");
    props.userStore.currentUser = {
      id: user.attributes.sub,
      email: user.attributes.email,
      emailVerified: user.attributes.emailVerified,
      idToken: user.signInUserSession.idToken.jwtToken,
      isAuthenticated: true,
    };

    if (newUser) {
      props.history.push("/inspiration");
    } else {
      props.history.push("/goals");
    }
  } catch (err) {
    if (err.code === "UserNotConfirmedException") {
      props.history.push({
        pathname: "/confirm-email",
        hash: `${email}`,
      });
    } else if (err.code === "PasswordResetRequiredException") {
      return "It looks like you need to reset your password. Please contact support.";
    } else if (err.code === "NotAuthorizedException") {
      return "Incorrect password. Please try again, or reset.";
    } else if (err.code === "UserNotFoundException") {
      return "User not found for this email address. Please sign up, or try another email.";
    } else {
      return err.message;
    }
  }
};

export const resendConfirmationCode = async (email: string) => {
  const username = email.toLowerCase().trim();

  try {
    await Auth.resendSignUp(username);
  } catch (err) {
    return err.message;
  }
};

export const authCurrentUser = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    return currentUser;
  } catch (err) {
    return false;
  }
};

export const authForgotPassword = async (props: IProps, email: string) => {
  const username = email.toLowerCase().trim();

  try {
    await Auth.forgotPassword(username);

    props.history.push("/reset-password");
  } catch (err) {
    return err.message;
  }
};

export const authResetPassword = async (
  props: IProps,
  email: string,
  code: string,
  newPassword: string
) => {
  const username = email.toLowerCase().trim();

  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword);

    props.history.push("/signin");
  } catch (err) {
    return err.message;
  }
};

export const authSignOut = async (props: IProps) => {
  try {
    await Auth.signOut();
    track("Signed Out");
    props.userStore.resetUser();
    props.history.push("/");
  } catch (err) {
    return err.message;
  }
};

export const getData = async (api: string, path: string, init: any) => {
  init.headers.Authorization = (await Auth.currentSession())
    .getIdToken()
    .getJwtToken();
  return await API.get(api, path, init);
};

export const postData = async (api: string, path: string, init: any) => {
  init.headers.Authorization = (await Auth.currentSession())
    .getIdToken()
    .getJwtToken();
  return await API.post(api, path, init);
};

export const putData = async (api: string, path: string, init: any) => {
  init.headers.Authorization = (await Auth.currentSession())
    .getIdToken()
    .getJwtToken();
  return await API.put(api, path, init);
};

export const patchData = async (api: string, path: string, init: any) => {
  init.headers.Authorization = (await Auth.currentSession())
    .getIdToken()
    .getJwtToken();
  return await API.patch(api, path, init);
};


export const getHeader = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const deleteData = async (api: string, path: string, init: any) => {
  let headers = await getHeader();
  if (!init) {
    init = {};
  }
  if (!init.headers) {
    init.headers = {
      Authorization: headers,
    };
  }
  if (headers) {
    init.headers.Authorization = headers;
  }
  return await API.del(api, path, init);
};

