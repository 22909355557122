// Packages
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import React from "react";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { PlusCircleFilled } from "@ant-design/icons";
// Styles
import "./GoalTempCard.scss";
import { Tag } from "antd";
// Type Definitions
import { IGoalTemplate } from "../../../shared/interfaces";

interface IProps {
  goal: IGoalTemplate;
  goalStore?: any;
  setActiveTab?: any;
}

@inject("goalStore")
@observer
class GoalTempCard extends React.Component<IProps> {
  @observable
  public image = this.props.goal.image;

  @computed get descriptionNew() {
    return (
      this.props.goal.description.charAt(0).toUpperCase() +
      this.props.goal.description.slice(1).toLowerCase()
    );
  }
  @action.bound
  public selectTemplate = () => {
    this.props.goalStore.activeGoalTemplate = this.props.goal;
    this.props.goalStore.createGoalModal = true;
  };

  render() {
    return (
      <IonCard className="inspirationGoalTempCard goal-temp-card">
        <div className="temp-card-header">
          <Link
            onClick={() => {
              this.props.setActiveTab("all");
            }}
            to={`/inspiration?goalTemplateId=${this.props.goal.id}`}
            title="View activities"
          >
            <IonCardHeader className="template-card-header montX">
              {/* <IonCardSubtitle className="mont M">
            <span>GOAL</span>
          </IonCardSubtitle> */}
              <IonCardTitle>{this.props.goal.name}</IonCardTitle>
            </IonCardHeader>
          </Link>
          <PlusCircleFilled
            className="plus-button"
            onClick={() => this.selectTemplate()}
          />
        </div>
        <IonCardContent className="content-container">
          <div className="template-para">{this.descriptionNew}</div>
          {/* Explore Goal */}
          <div className="card-tags">
            {this.props.goal.categories &&
              this.props.goal.categories.map((category) => {
                const title = category.toLowerCase();
                return (
                  <Tag key={category} title={title}>
                    {title}
                  </Tag>
                );
              })}
          </div>
          <div className="img-container">
            <img
              className="goal-temp-card-img"
              src={this.image}
              alt={this.props.goal.name}
              onError={(e) => {
                this.image = "/assets/golden-hour.jpg";
              }}
            />
          </div>
        </IonCardContent>
      </IonCard>
    );
  }
}

export default GoalTempCard;
