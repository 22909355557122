// Packages
import React from "react";
import { IonPage, IonModal, IonSpinner, IonButton } from "@ionic/react";
import { observable, computed, autorun, action, reaction } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import { Link } from "react-router-dom";
// Components
import GoalTempCard from "../../shared/cards/GoalTempCard/GoalTempCard";
import ActivityTempCard from "../../shared/cards/ActivityTempCard/ActivityTempCard";
import GoalForm from "../../shared/forms/GoalForm/GoalForm";
import ActivityForm from "../../shared/forms/ActivityForm/ActivityForm";
import { LineChartOutlined } from "@ant-design/icons";
import { Breadcrumb, Alert } from "antd";
import Masonry from "react-masonry-css";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { IGoalTemplate, IActivityTemplate } from "../../shared/interfaces";
import { ActiveInspirationSVG } from "../../components/svgComponents/ActiveInspirationSVG";

interface IProps extends RouteComponentProps {
  goalStore: any;
  activityStore: any;
  location: any;
}

const breakpointCols = {
  default: 4,
  2000: 3,
  1400: 2,
  700: 2,
  500: 1,
};

@inject("goalStore", "activityStore")
@observer
class Inspiration extends React.Component<IProps> {
  @observable
  public loading = false;

  @observable
  public activeTab = "all";

  @observable
  public queryExplainer = "";

  @computed get goalTempFilter() {
    const id = new URLSearchParams(this.props.location.search).get(
      "goalTemplateId"
    );
    const goalTemplate = this.goalTemplates.find(
      (gt: IGoalTemplate) => gt.id === id
    );
    if (goalTemplate) {
      return goalTemplate;
    } else {
      return {};
    }
  }

  @computed get onlyActivities() {
    return new URLSearchParams(this.props.location.search).get(
      "onlyActivities"
    );
  }

  @computed get goalTemplates() {
    return this.props.goalStore.goalTemplates;
  }

  @computed get unusedGoalTemplates() {
    return this.goalTemplates.filter((template: IGoalTemplate) => {
      return !this.props.goalStore.usedGoalTemplateIds.includes(template.id);
    });
  }

  @computed get activityTemplates() {
    return this.props.activityStore.activityTemplates.filter(
      (template: IActivityTemplate) => {
        return !this.props.activityStore.usedActivityTemplateIds.includes(
          template.id
        );
      }
    );
  }
  @computed get routineTemplates() {
    return this.props.activityStore.activityTemplates.filter(
      (template: IActivityTemplate) => {
        return (
          !this.props.activityStore.usedActivityTemplateIds.includes(
            template.id
          ) && template.type === "routine"
        );
      }
    );
  }
  @computed get taskTemplates() {
    return this.props.activityStore.activityTemplates.filter(
      (template: IActivityTemplate) => {
        return (
          !this.props.activityStore.usedActivityTemplateIds.includes(
            template.id
          ) && template.type === "task"
        );
      }
    );
  }

  @computed get fetchId() {
    return new URLSearchParams(this.props.location.search).get("id");
  }
  @computed get fetchType() {
    return new URLSearchParams(this.props.location.search).get("type");
  }

  @disposeOnUnmount
  public inspirationAutorun = autorun(() => {
    this.getGoalTemplates();
    this.props.goalStore.getGoals();
    this.props.activityStore.getActivities("task");
    this.props.activityStore.getActivities("routine");
  });

  @disposeOnUnmount
  public goalTempFilterReaction = reaction(
    () => this.goalTempFilter,
    (goalTemplate) => {
      this.props.activityStore.activityTemplates = [];
      this.getActivityTemplates(goalTemplate.id);
    }
  );

  @action.bound
  public getGoalTemplates = () => {
    if (this.goalTemplates.length < 1) this.loading = true;
    this.props.goalStore
      .getGoalTemplates()
      .then((goalTemplates: IGoalTemplate[]) => {
        this.loading = false;
      });
  };

  @action.bound
  public getActivityTemplates = (goalTemplateId: string | null) => {
    if (this.activityTemplates.length < 1) this.loading = true;
    this.props.activityStore
      .getActivityTemplates(goalTemplateId)
      .then((activityTemplates: IActivityTemplate[]) => {
        this.loading = false;
        if (this.fetchId && this.fetchType) {
          this.props.activityStore.activeActivityTemplate =
            activityTemplates.find(
              (template: IActivityTemplate) => template.id === this.fetchId
            );
          this.props.activityStore.createActivityModal = true;
          this.props.activityStore.createActivityType = this.fetchType;
        }
        if (activityTemplates && activityTemplates.length < 1) {
          this.queryExplainer = "Sorry! No activities found.";
        } else {
          this.queryExplainer = "";
        }
      });
  };

  @action.bound
  public renderBreadcrumbs() {
    if (this.goalTempFilter.name) {
      return (
        <Breadcrumb>
          <Breadcrumb.Item key="inspiration">
            <Link to="/inspiration">Inspiration</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="goalTempFilter">
            <LineChartOutlined />
            <span>{this.goalTempFilter.name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      );
    }
  }

  @action.bound
  public setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  @action.bound
  public renderGoals() {
    if (!this.goalTempFilter.name && !this.onlyActivities) {
      return (
        <>
          <h1 className="mont M">Goals</h1>
          <Masonry
            breakpointCols={breakpointCols}
            className="masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {this.unusedGoalTemplates.length > 0 &&
              this.unusedGoalTemplates.map((goalTemplate: IGoalTemplate) => {
                return (
                  <li key={goalTemplate.id}>
                    <GoalTempCard
                      goal={goalTemplate}
                      setActiveTab={this.setActiveTab}
                    />
                  </li>
                );
              })}
          </Masonry>
        </>
      );
    }
  }

  @action.bound
  public closeNewGoalModal() {
    this.props.goalStore.createGoalModal = false;
  }

  @action.bound
  public closeNewActivityModal() {
    this.props.activityStore.createActivityModal = false;
    if (this.fetchType || this.fetchId) this.props.history.push("/inspiration");
  }

  render() {
    return (
      <IonPage className="scroll-page inspiration-page">
        <div className="main-content ion-padding">
          {/* {this.renderBreadcrumbs()} */}
          <div className="header">
            <h1 className="SiteHeader">
              <div className="IconWrapper">
                <ActiveInspirationSVG />
              </div>
              Inspiration
            </h1>
            <div className="tab-buttons">
              <button
                className={
                  this.activeTab === "all" ? "activeTab" : "inactiveTab"
                }
                onClick={() => {
                  this.activeTab = "all";
                  this.props.activityStore.getActivities("task");
                  this.props.activityStore.getActivities("routine");
                }}
              >
                All
              </button>
              <button
                className={
                  this.activeTab === "goal" ? "activeTab" : "inactiveTab"
                }
                onClick={() => {
                  this.activeTab = "goal";
                  this.props.activityStore.getActivities("goal");
                }}
              >
                Goals
              </button>
              <button
                className={
                  this.activeTab === "routine" ? "activeTab" : "inactiveTab"
                }
                onClick={() => {
                  this.activeTab = "routine";
                  this.props.activityStore.getActivities("goal");
                }}
              >
                Routines
              </button>
              <button
                className={
                  this.activeTab === "task" ? "activeTab" : "inactiveTab"
                }
                onClick={() => {
                  this.activeTab = "task";
                  this.props.activityStore.getActivities("task");
                }}
              >
                Tasks
              </button>
            </div>
          </div>
          {this.loading ? (
            <IonSpinner className="loading" />
          ) : (
            <>
              {(this.activeTab === "all" || this.activeTab === "goal") && (
                <>{this.renderGoals()}</>
              )}
              {(this.activeTab === "all" || this.activeTab === "routine") && (
                <>
                  <h1 className="mont M">Routines</h1>
                  {this.queryExplainer.length > 0 ? (
                    <Alert
                      message={this.queryExplainer}
                      type="warning"
                      showIcon
                      className="inline-alert"
                    />
                  ) : null}
                  <Masonry
                    breakpointCols={breakpointCols}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {this.routineTemplates.length > 0 &&
                      this.routineTemplates.map(
                        (activityTemplate: IActivityTemplate) => {
                          return (
                            <React.Fragment key={activityTemplate.id}>
                              <ActivityTempCard
                                activity={activityTemplate}
                                cardType={"routine"}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                  </Masonry>
                </>
              )}
              {(this.activeTab === "all" || this.activeTab === "task") && (
                <>
                  <h1 className="mont M">Tasks</h1>
                  {this.queryExplainer.length > 0 ? (
                    <Alert
                      message={this.queryExplainer}
                      type="warning"
                      showIcon
                      className="inline-alert"
                    />
                  ) : null}
                  <div className="collective-routines">
                    {this.taskTemplates.length > 0 &&
                      this.taskTemplates.map(
                        (activityTemplate: IActivityTemplate) => {
                          return (
                            <li key={activityTemplate.id}>
                              <ActivityTempCard
                                activity={activityTemplate}
                                cardType={"task"}
                              />
                            </li>
                          );
                        }
                      )}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <IonModal
          cssClass={"new-goal-modal"}
          isOpen={this.props.goalStore.createGoalModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <GoalForm closeNewGoalModal={this.closeNewGoalModal} />
          </div>
        </IonModal>

        <IonModal
          cssClass={
            this.props.activityStore.createActivityType === "routine" ||
            this.fetchType === "routine"
              ? "routine-modal"
              : "activity-modal"
          }
          isOpen={this.props.activityStore.createActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <ActivityForm closeNewActivityModal={this.closeNewActivityModal} />
          </div>
        </IonModal>
      </IonPage>
    );
  }
}

export default Inspiration;
