// Packages
import React from "react";
import { IonDatetime } from "@ionic/react";
import { DatePicker } from "antd";
import moment from "moment";
import { isMobileDevice } from "../../utils";
// Type Definitions
interface IProps {
  defaultValue: any;
  defaultPickerValue: any;
  value: any;
  onChange: any;
  onOpenChange: any;
  customClassName: string;
}

class CustomDatePicker extends React.Component<IProps> {
  render() {
    const { value } = this.props;
    if (isMobileDevice()) {
      return (
        <IonDatetime
          displayFormat="MM/DD/YYYY"
          placeholder="Select Date"
          value={value ? value.toISOString() : null}
          onIonChange={(event) =>
            this.props.onChange(moment(String(event.detail.value)))
          }
          className="ant-input"
          style={{ marginBottom: "0px" }}
          min={moment(new Date()).subtract(5, "years").format()}
          max={moment(new Date()).add(50, "years").format()}
          onIonCancel={this.props.onOpenChange}
        />
      );
    } else {
      return (
        <DatePicker
          className={this.props.customClassName}
          style={{ marginBottom: "0px" }}
          placeholder="Date (required)"
          defaultValue={this.props.defaultValue}
          defaultPickerValue={this.props.defaultPickerValue}
          value={this.props.value}
          onChange={this.props.onChange}
          onOpenChange={this.props.onOpenChange}
          format="MMM DD, YYYY"
        />
      );
    }
  }
}

export default CustomDatePicker;
