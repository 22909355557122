// Packages
import React from "react";
import { observable, action, autorun } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import _ from "lodash";
import { track } from "../../../plugins/analytics";

// Components
import DatePicker from "../../DatePicker/DatePicker";
import {
  CalendarOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
// Styles
import {
  Popconfirm,
  Input,
  Button,
  Alert,
  message,
  Form,
  Select,
} from "antd";
// Type Definitions
import { IActivity, IGoal } from "../../interfaces";
import { IonSelect, IonSelectOption, IonSpinner } from "@ionic/react";
import moment from "moment";
import { HistorySVG } from "../../../components/svgComponents/HistorySVG";

interface IProps {
  activityStore?: any;
  goalStore?: any;
  updateCheckIn?: any;
  closeActivityModal: () => void;
  checkInStore?: any;
}

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

@inject("activityStore", "goalStore", "checkInStore")
@observer
class EditActivityForm extends React.Component<IProps> {
  @observable public loading = false;
  @observable public datePickerOpened = false;
  @observable public error = null;
  @observable public editedActivity: IActivity = this.initActivity();
  @observable tempDate: moment.Moment | null = null;
  @observable public isDropdownOpen = false;
  @action.bound
  public handleInputChange(key: string, value: any) {
    switch (key) {
      case "name":
        this.editedActivity.name = value;
        break;
      case "description":
        this.editedActivity.description = value;
        break;
      case "goalId":
        this.editedActivity.goalId = value;
        break;
      case "completionDate":
        if (!value) {
          this.editedActivity.completionType = "";
        } else {
          this.editedActivity.completionDate = value;
        }
        break;
      case "completionType":
        this.editedActivity.completionType = value;
        this.editedActivity.completionDate = moment();
        break;
      case "completedOn":
        if (!value) {
          this.editedActivity.completionType = "";
        } else {
          this.editedActivity.completedOn = value;
        }
        break;
      case "frequency":
        const frequency = Number.isInteger(Number(value)) ? Number(value) : 0;
        this.editedActivity.frequency = frequency;
        break;
      default:
        console.log("Unknown form value change");
    }
  }

  @action.bound
  public initActivity() {
    const editActiveActivity = { ...this.props.activityStore.activeActivity };
    if (editActiveActivity.type === "task") {
      return {
        ...editActiveActivity,
        completionDate: moment(editActiveActivity.completionDate),
        ...(editActiveActivity.completedOn && {
          completedOn: moment(editActiveActivity.completedOn),
        }),
      };
    } else if (editActiveActivity.type === "routine") {
      return {
        ...editActiveActivity,
        completionDate: moment(editActiveActivity.completionDate),
        ...(editActiveActivity.completedOn && {
          completedOn: moment(editActiveActivity.completedOn),
        }),
      };
    }
    return editActiveActivity;
  }

  @disposeOnUnmount
  public resetState = autorun(() => {
    if (this.props.activityStore.updateActivityModal) {
      this.editedActivity = this.initActivity();
    }
  });

  @action.bound
  public handleSubmit = async (typeTitle: string) => {
    this.loading = true;
    track("Activity Edited");

    const resError = await this.props.activityStore.updateActivity(
      this.editedActivity,
      this.editedActivity.isActive,
      this.props.updateCheckIn && this.props.updateCheckIn
    );
    this.error = resError;
    this.loading = false;
    if (!this.error) {
      this.props.activityStore.updateActivityModal = false;
      // if(this.props.checkInStore.activeCheckIn) {
      //   this.props.checkInStore.getCheckIn(this.props.checkInStore.activeCheckIn.id);
      // }
      message.success(`${typeTitle} updated!`);
    }
  };

  public isSubmitButtonDisabled = () => {
    return (
      this.editedActivity.name === undefined ||
      this.editedActivity.name === "" ||
      this.editedActivity.completionDate === null
    );
  };

  public renderSubmitBtn(typeTitle: string) {
    if (this.loading) {
      return (
        <Button type="primary" className="form-submit-button" disabled>
          <LoadingOutlined />
        </Button>
      );
    } else if (
      !this.isSubmitButtonDisabled() &&
      this.editedActivity.goalId === "0"
    ) {
      return (
        <Popconfirm
          title={
            typeTitle === "Task"
              ? "Hold up! This task is not tied to a goal."
              : "Hold up! This routine is not tied to a goal."
          }
          onCancel={() => {
            track("Goal Association Nudge Ignored");
            this.handleSubmit(typeTitle);
          }}
          okText="Wait, I'll assign a goal"
          cancelText="Add it with no goal"
        >
          <Button type="primary" className="form-submit-button">
            Edit {typeTitle}
          </Button>
        </Popconfirm>
      );
    } else {
      return (
        <Button
          type="primary"
          className="form-submit-button"
          onClick={() => this.handleSubmit(typeTitle)}
          disabled={this.isSubmitButtonDisabled()}
        >
          Edit {typeTitle}
        </Button>
      );
    }
  }

  public renderFields() {
    const { activeActivity } = this.props.activityStore;
    const status = activeActivity.status;
    const { type } = this.props.activityStore.activeActivity;
    if (type === "routine")
      return (
        <Form.Item style={{ marginBottom: "0px", flex: "1", display: "flex" }}>
          <div className="actions-container">
            <div className="occurence-container">
              <HistorySVG />
              Set occurrence
            </div>
            <div className="counter-container">
              <Button
                className="plus-minus-button"
                onClick={() =>
                  this.editedActivity.frequency
                    ? this.editedActivity.frequency > 1 &&
                      this.editedActivity.frequency--
                    : (this.editedActivity.frequency = 1)
                }
                icon={<MinusOutlined />}
                size="small"
              />
              <input
                className="routine-form-input"
                name="frequency"
                value={this.editedActivity.frequency}
                min={1}
                required
                onChange={(e: any) => {
                  this.handleInputChange("frequency", e.target.value);
                }}
              />
              <Button
                className="plus-minus-button"
                onClick={() =>
                  this.editedActivity.frequency
                    ? this.editedActivity.frequency >= 1 &&
                      this.editedActivity.frequency++
                    : null
                }
                icon={<PlusOutlined />}
                size="small"
              />
              <div className="routine-form-item-text">PER WEEK</div>
            </div>
          </div>
        </Form.Item>
      );
    return (
      <>
        {activeActivity.status === "incomplete" && (
          <Form.Item
            style={{
              marginBottom: "0px",
              minWidth: "fit-content",
              width: "4rem",
            }}
          >
            <>
              {this.editedActivity.completionType === "scheduled" ? (
                <DatePicker
                  customClassName="new-date-picker"
                  defaultValue={moment(this.editedActivity.completionDate)}
                  defaultPickerValue={moment(
                    this.editedActivity.completionDate
                  )}
                  value={moment(this.editedActivity.completionDate)}
                  onChange={(value: any) =>
                    this.handleInputChange("completionDate", value)
                  }
                  onOpenChange={(isOpen: boolean) => {
                    this.datePickerOpened = isOpen;
                  }}
                />
              ) : (
                <Select
                  className="date-picker-select"
                  dropdownClassName="date-picker-dropdown"
                  defaultValue={this.editedActivity.completionType}
                  value={this.editedActivity.completionType}
                  onChange={(e) => {
                    this.handleInputChange("completionType", e);
                  }}
                  showArrow={false}
                  dropdownStyle={{ transform: "translateY(-1)" }}
                  onFocus={() => (this.isDropdownOpen = true)}
                  onBlur={() => (this.isDropdownOpen = false)}
                >
                  {!this.isDropdownOpen && (
                    <Select.Option className="select-option" key="0" value="">
                      <CalendarOutlined style={{ fill: "#413D45" }} /> When
                    </Select.Option>
                  )}
                  <Select.Option
                    className="select-option"
                    key="1"
                    value="scheduled"
                  >
                    Scheduled
                  </Select.Option>
                  <Select.Option className="select-option" key="2" value="soon">
                    Soon
                  </Select.Option>
                  <Select.Option
                    className="select-option"
                    key="3"
                    value="someday"
                  >
                    Someday
                  </Select.Option>
                </Select>
              )}
            </>
          </Form.Item>
        )}
        {activeActivity.status === "complete" &&
          this.editedActivity.completionType === "scheduled" && (
            <Form.Item
              style={{
                marginBottom: "0px",
                minWidth: "fit-content",
                width: "4rem",
              }}
            >
              <DatePicker
                customClassName="new-date-picker"
                defaultValue={this.editedActivity.completedOn}
                defaultPickerValue={this.editedActivity.completedOn}
                value={this.editedActivity.completedOn}
                onChange={(value: any) =>
                  this.handleInputChange("completedOn", value)
                }
                onOpenChange={(isOpen: boolean) => {
                  this.datePickerOpened = isOpen;
                }}
              />
            </Form.Item>
          )}
        {activeActivity.status === "complete" &&
          this.editedActivity.completionType !== "scheduled" && (
            <Select
              className="date-picker-select"
              dropdownClassName="date-picker-dropdown"
              defaultValue={this.editedActivity.completionType}
              value={this.editedActivity.completionType}
              onChange={(e) => {
                this.handleInputChange("completionType", e);
              }}
              showArrow={false}
              dropdownStyle={{ transform: "translateY(-1)" }}
              onFocus={() => (this.isDropdownOpen = true)}
              onBlur={() => (this.isDropdownOpen = false)}
            >
              {!this.isDropdownOpen && (
                <Select.Option className="select-option" key="0" value="">
                  <CalendarOutlined style={{ fill: "#413D45" }} /> When
                </Select.Option>
              )}
              <Select.Option
                className="select-option"
                key="1"
                value="scheduled"
              >
                Scheduled
              </Select.Option>
              <Select.Option className="select-option" key="2" value="soon">
                Soon
              </Select.Option>
              <Select.Option className="select-option" key="3" value="someday">
                Someday
              </Select.Option>
            </Select>
          )}
      </>
    );
  }

  render() {
    const typeTitle = _.startCase(this.props.activityStore.activeActivity.type);
    const { TextArea } = Input;

    return (
      <>
        {this.loading ? (
          <div className="center">
            <IonSpinner />
          </div>
        ) : (
          <div className="goal-form-wrapper">
            <div className="error-container">
              {this.error ? (
                <Alert message={this.error} type="error" showIcon />
              ) : (
                ""
              )}
            </div>
            <h3 className="montX XL">Edit {typeTitle}</h3>

            <Form className="goal-form" {...formItemLayout}>
              <Form.Item>
                <label className="label" htmlFor="name">
                  Name*
                </label>
                <Input
                  name="name"
                  placeholder="Name (required)"
                  value={this.editedActivity.name}
                  required
                  onChange={(event: any) =>
                    this.handleInputChange("name", event.currentTarget.value)
                  }
                />
              </Form.Item>

              <Form.Item>
                <label className="label" htmlFor="description">
                  Description
                </label>
                <TextArea
                  style={{ minHeight: "90px", resize: "none" }}
                  name="description"
                  placeholder="Description"
                  value={this.editedActivity.description}
                  onChange={(event: any) =>
                    this.handleInputChange(
                      "description",
                      event.currentTarget.value
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                <div
                  className={`${"flexBoxRow"} ${
                    typeTitle === "Routine" ? "flex-wrap" : ""
                  }`}
                >
                  <div
                    className={`${"ant-input-mimic"} 
                ${typeTitle === "Routine" ? "fullWidth" : ""}`}
                  >
                    <IonSelect
                      defaultValue={"0"}
                      value={this.editedActivity.goalId}
                      onIonChange={(e) =>
                        this.handleInputChange("goalId", e.detail.value)
                      }
                      className={typeTitle === "Task" ? "goal-text-select" : ""}
                    >
                      <IonSelectOption key="0" value="0">
                        No Goal Selected
                      </IonSelectOption>
                      {this.props.goalStore.goals.map((goal: IGoal) => {
                        if (
                          goal.status === "complete" &&
                          this.editedActivity.goalId !== goal.id
                        ) {
                          return null;
                        } else if (
                          goal.isActive === 0 &&
                          this.editedActivity.goalId !== goal.id
                        ) {
                          return null;
                        }
                        return (
                          <IonSelectOption
                            className="ionic-goal-picker"
                            key={goal.id}
                            value={goal.id}
                          >
                            {goal.name}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  </div>
                  {this.renderFields()}
                </div>
              </Form.Item>
              <div className="form-buttons-wrapper">
                <Button
                  className="cancel-button"
                  onClick={this.props.closeActivityModal}
                >
                  Cancel
                </Button>
                {this.renderSubmitBtn(typeTitle)}
              </div>
            </Form>
          </div>
        )}
      </>
    );
  }
}

export default EditActivityForm;
